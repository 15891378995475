import React, { useState } from 'react';

import mainLogo from "../assets/img/DevOpsShell_logo.png";
import smallLogo from "../assets/img/DevOpsShell_dock.png";

function License() {
  const detectDownload = () => {
    if(window.navigator.appVersion.indexOf("Mac")>-1){
      macDownload();
    }else if(window.navigator.appVersion.indexOf("Win")>-1){
      windowsDownload();
    }else{
      macDownload();
    }
  }

  const macDownload = () => {
    window.location.href = "https://devopsshell.com/api/getLatestMacVersion";
  }

  const windowsDownload = () => {
    window.location.href = "https://devopsshell.com/api/getLatestWindowsVersion";
  }

  return (



<>



<header className="header">
          <div className="header-top">
              <div className="container">
                  <div className="row">
                      <div className="col-xl-4 col-lg-5">
                          <div className="support">
                              <ul>
                                  {/* <li>
                                      <div className="lang-change-button">
                                          <select>
                                              <option>English</option>
                                              <option>Bengali</option>
                                              <option>Hindi</option>
                                          </select>
                                          <div className="icon">
                                              <i className="icofont-world"></i>
                                          </div>
                                      </div>
                                  </li> */}
                                  <li><span><i className="icofont-email"></i></span><a href = "mailto:support@devopsshell.com" style = {{fontSize:"14px",color:"#fff"}}>Support@DevOpsShell.com</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        <nav className="header-bottom mt-lg-5 mt-0 fixed-header">
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-xl-3 col-lg-3 d-xl-none d-lg-none d-block">
                        <div className="mobile-style">
                            <div className="row">
                                <div className="col-6">
                                    <div className="mobile-logo">
                                        <a href="index.html"><img src={smallLogo} alt=""/></a>
                                    </div>
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                    <div className="menu-button">
                                        <button className="responsive-menu-button">
                                            <i className="icofont-navigation-menu"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 d-xl-block d-lg-block d-none">

                        <div className="mobile-logo">
                          <a href = "/"><img src={mainLogo} alt="" className="web-logo"/></a>
                        </div>

                    </div>
                    <div className="col-xl-9 col-lg-9" style = {{paddingRight: "10px"}}>
                        <div className="main-menu">
                            <ul>
                                <li><a href="/docs">View Docs</a></li>
                                <li><a href="/privacy">Privacy Policy</a>
                                    {/*
                                    <i className="icofont-rounded-down"></i>
                                    <ul>
                                        <li><a href="#!" onClick = {standardClick}>Standard</a></li>
                                        <li><a href="#!" onClick = {enterpriseClick}>Enterprise</a></li>
                                    </ul> */}
                                </li>
                                <li><div id = "headerDownloadButton" onClick = {detectDownload}><span style = {{paddingRight: "10px"}}>&darr;</span><span>Download</span></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header> 














    <span id = "backHome"><a href = "/">Back Home</a></span>

    <div id = "documentation" style = {{height:"80vh"}}>
      <div>
        <div className = "command-title">
          License
        </div>
        <div className = "description" style = {{maxWidth: "900px"}}>
          Acquiring Licenses directly from our website is currently still in development. For now please email <a href = "mailto:support@devopsshell.com">support@devoppshell.com</a> requesting a license and we will respond as soon as we can thank you!
        </div><p></p>
      </div><br/><br/>

    </div>



    <footer className="footer">
  
  <div className="footer-bottom">
      <div className="container">
          <div className="row">
              <div className="col-xl-6 col-lg-6">
                  <div className="copiright">
                      <p>
                          DevOpsShell MATRIONIX Corp.<br/>
                          Copyright © 2021.All Rights Reserved By Matrionix Corp.<br/>
                          DevOpsShell Trademark Matrionix Corp. Patent Pending
                      </p>
                  </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                  <div className="social">
                      <a href="#!"><i className="icofont-facebook"></i></a>
                      <a href="#!"><i className="icofont-twitter"></i></a>
                      <a href="#!"><i className="icofont-pinterest"></i></a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</footer>



    </>
  );
}

export default License;
