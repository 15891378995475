import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import './App.css';
import Home from "./Home";
import Docs from "./Docs";
import Privacy from "./Privacy";
import License from "./License";

function App({store}) {
  return (
    <React.Fragment>
      <Router>
        <Route exact path = "/" render={()=><Home store = {store}/>}/>
        <Route exact path = "/docs" render={()=><Docs store = {store}/>}/>
        <Route exact path = "/privacy" render={()=><Privacy store = {store}/>}/>
        <Route exact path = "/license" render={()=><License store = {store}/>}/>
      </Router>
    </React.Fragment>

  );
}

export default App;
