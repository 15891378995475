import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from "mobx-react";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";
import CircularProgress from '@material-ui/core/CircularProgress';
import { doesNotReject } from 'assert';

const useStyles = makeStyles((theme) => ({
  root: {
    //maxWidth: 400,
    flexGrow: 1,
    width: "500px",
    height: "500px",
    display: "flex",
    justifyContent: "center"
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));

function DialogComponent({store}) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 2;

  const [email,updateEmail] = useState("");
  const [error,setError] = useState("");
  const [isLoading,setLoading] = useState(false);
  const [display,setDisplay] = useState("block");

  const handleNext = () => {
    if(emailValidity()){
      window.localStorage.email = email;
      store.setDialogView("payment");
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setError("");
    }else{
      setError("Please enter a valid email address.");
    }
  };

  const handleBack = () => {
    store.setDialogView("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setError("");
  };

  const emailValidity = () => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)){
      return true;
    }else{
        return false;
    }
  }

  const closeDialog = () => {
    store.setDialogView("");
    store.hideDialog();
  }
  var price = "";
  if(window.localStorage.licenseType === "Standard"){
    price = "$59.99"
  }if(window.localStorage.licenseType === "Enterprise"){
    price = "$159.99"
  }
  

  return (
    <div>
        <Dialog aria-labelledby="customized-dialog-title" open={true} id = "dialog">
          <CloseIcon id = "closeButton" onClick = {closeDialog}/>
          <div className={classes.root}>
            {
              store.dialogView === ""
              ?
                <>
                  <h3 id = "dialogMessage">Please enter your email address.</h3>
                  <input
                    id="emailInput"
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e)=>{updateEmail(e.target.value)}}
                  />
                </>
              :
                <>
                {
                  store.dialogView === "payment"
                  ?
                    <div className="col-xs-12" style = {{display: display}}>
                      <div className="pricing-package foo priceBox" data-sr="enter" data-sr-id="12">
                          <div className="pricing-header">
                            <div className="pricing-tag">
                              {price}
                            </div>
                          </div>
                          <div className="pricing-body">
                            <h2 className="pricing-title">{`${window.localStorage.licenseType} License`}</h2>
                          </div>
                          <div className="pricing-footer">
                  {
                    window.localStorage.licenseType === "Standard"
                    ?
                      <div>
                        <PayPalButton
                          options={{
                            vault: true,
                            clientId: "Ad-umFCSXc2zGdHnv_q1XLwdSsflvkhcMkDjb4m3w9u_SU6mRZpFLqsf4Oesj7CZ78VcGQHbb-0a9cDJ"
                          }}
                          amount="59.99"
                          style = {{
                            color: "blue" 
                          }}
                          clientId = ""
                          createOrder={(data, actions) => {
                            setLoading(true);
                            setDisplay("none");
                            return actions.order.create({
                              purchase_units: [{
                                amount: {
                                  currency_code: "USD",
                                  value: "59.99"
                                }
                              }],
                            });
                          }}
                          onApprove={(data, actions) => {
                            return actions.order.capture().then(function(details) {
                              axios.post("https://devopsshell.com/api/getStandardLicense",{data: details,email:window.localStorage.email}).then((res)=>{
                                setLoading(false);
                                if(res.data.status==="success"){
                                  store.setDialogView("paymentSuccess");
                                }else{
                                  setError("an unexpected problem occured, please try again later.");
                                }
                              }).catch((err)=>{console.log(err)});
                            });
                          }}
                          onCancel = {()=>{
                            setLoading(false);
                            setDisplay("block");
                          }}
                        />
                      </div>
                      :
                      <div>
                        <PayPalButton
                          options={{
                            vault: true,
                            clientId: "Ad-umFCSXc2zGdHnv_q1XLwdSsflvkhcMkDjb4m3w9u_SU6mRZpFLqsf4Oesj7CZ78VcGQHbb-0a9cDJ"
                          }}
                          amount="159.99"
                          style = {{
                            color: "blue" 
                          }}
                          createOrder={(data, actions) => {
                            setLoading(true);
                            setDisplay("none");
                            return actions.order.create({
                              purchase_units: [{
                                amount: {
                                  currency_code: "USD",
                                  value: "159.99"
                                }
                              }],
                            });
                          }}
                          onApprove={(data, actions) => {
                            return actions.order.capture().then(function(details) {
                              axios.post("https://devopsshell.com/api/getEnterpriseLicense",{data: details,email:window.localStorage.email}).then((res)=>{
                                setLoading(false);
                                if(res.data.status==="success"){
                                  store.setDialogView("paymentSuccess");
                                }else{
                                  setError("an unexpected problem occured, please try again later.");
                                }
                              }).catch((err)=>{console.log(err)});
                            });
                          }}
                          onCancel = {()=>{
                            setLoading(false);
                            setDisplay("block");
                          }}
                        />
                      </div>
                    }
                    </div>  
                    </div>  
                    </div>  
                    :
                    <>
                    {
                    store.dialogView === "paymentSuccess"
                    ?
                    <div id = "licenseSuccess">
                      <h2>Thank You!</h2>
                      <p>
                      An email containing your license ID and further instructions has been sent to you.
                      </p>
                    </div>
                    :
                    ""
                    }
                    </>
                }
                </>
            }
            {
              isLoading === true
              ?
              <div id = "spinnerContainer">
                <CircularProgress id = "spinner"/>
              </div>
              :
              ""
            }
            {
              error
              ?
              <div id = "error">
                {error}
              </div>
              :
              ""
            }
            <MobileStepper
              style = {{position: "absolute",bottom: "3px",width: "100%"}}
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                  Next
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  Back
                </Button>
              }
            />
          </div>
        </Dialog>
    </div>
  )
}

export default observer(DialogComponent);
