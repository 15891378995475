import React, { useState } from 'react';

import mainLogo from "../assets/img/DevOpsShell_logo.png";
import smallLogo from "../assets/img/DevOpsShell_dock.png";

function Docs() {
  const detectDownload = () => {
    if(window.navigator.appVersion.indexOf("Mac")>-1){
      macDownload();
    }else if(window.navigator.appVersion.indexOf("Win")>-1){
      windowsDownload();
    }else{
      macDownload();
    }
  }

  const macDownload = () => {
    window.location.href = "https://devopsshell.com/api/getLatestMacVersion";
  }

  const windowsDownload = () => {
    window.location.href = "https://devopsshell.com/api/getLatestWindowsVersion";
  }

  return (



<>



<header className="header">
          <div className="header-top">
              <div className="container">
                  <div className="row">
                      <div className="col-xl-4 col-lg-5">
                          <div className="support">
                              <ul>
                                  {/* <li>
                                      <div className="lang-change-button">
                                          <select>
                                              <option>English</option>
                                              <option>Bengali</option>
                                              <option>Hindi</option>
                                          </select>
                                          <div className="icon">
                                              <i className="icofont-world"></i>
                                          </div>
                                      </div>
                                  </li> */}
                                  <li><span><i className="icofont-email"></i></span><a href = "mailto:support@devopsshell.com" style = {{fontSize:"14px",color:"#fff"}}>Support@DevOpsShell.com</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        <nav className="header-bottom mt-lg-5 mt-0 fixed-header">
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-xl-3 col-lg-3 d-xl-none d-lg-none d-block">
                        <div className="mobile-style">
                            <div className="row">
                                <div className="col-6">
                                    <div className="mobile-logo">
                                        <a href="index.html"><img src={smallLogo} alt=""/></a>
                                    </div>
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                    <div className="menu-button">
                                        <button className="responsive-menu-button">
                                            <i className="icofont-navigation-menu"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 d-xl-block d-lg-block d-none">

                        <div className="mobile-logo">
                          <a href = "/"><img src={mainLogo} alt="" className="web-logo"/></a>
                        </div>

                    </div>
                    <div className="col-xl-9 col-lg-9" style = {{paddingRight: "10px"}}>
                        <div className="main-menu">
                            <ul>
                                <li><a href="/docs">View Docs</a></li>
                                <li><a href="/privacy">Privacy Policy</a>
                                    {/*
                                    <i className="icofont-rounded-down"></i>
                                    <ul>
                                        <li><a href="#!" onClick = {standardClick}>Standard</a></li>
                                        <li><a href="#!" onClick = {enterpriseClick}>Enterprise</a></li>
                                    </ul> */}
                                </li>
                                <li><div id = "headerDownloadButton" onClick = {detectDownload}><span style = {{paddingRight: "10px"}}>&darr;</span><span>Download</span></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header> 
















    <div id = "documentation">
    <div className = "doc-title">Docs <span id = "version">v1.1.0</span><span id = "backHome"><a href = "/">Back Home</a></span></div><br/>
      <div>
        <div className = "command-title">
          Environment
        </div>
        <div className = "description">
          Environments are used to organize and store data relative to a category.
          If the specified environment does not already exist, a new empty environment will be created.
        </div><p></p>
        <div className = "syntax-title">
          (Syntax)
        </div>
        <div className = "syntax">
          :env:"Environment Name"
        </div>
      </div><br/><br/>


      <div>
        <div className = "command-title">
          Header
        </div>
        <div className = "description">
          Headers are you used to display a visual title usually for categorization. Headers are local meaning they only apply to the environment in which they are created in.
        </div><p></p>
        <div className = "syntax-title">
          (Syntax)
        </div>
        <div className = "syntax">
          :header:"Header Name"
        </div>
      </div><br/><br/>


      <div>
        <div className = "command-title">
          Prefix
        </div>
        <div className = "description">
        Prefixes are used to specify the prefix of data without a specified name.
        Prefixes are local meaning they only apply to the environment in which they are created in.
        </div><p></p>
        <div className = "syntax-title">
          (Syntax)
        </div>
        <div className = "syntax">
          :prefix:"myOS"
        </div>
      </div><br/><br/>

      <div>
        <div className = "command-title">
          Commands
        </div>
        <div className = "description">
        Commands are used to store commands. One command object can store unlimited commands. Commands cannot be combined with other data types during execution.
        </div><p></p>
        <div className = "syntax-title">
          (Named Assignment Syntax)
        </div>
        <div className = "syntax">
          :cmd:cmdName:"command 1","command 2"
        </div><br/>

        <div className = "syntax-title">
        (Nameless Assignment Syntax)
        </div>
        <div className = "syntax">
        :cmd:"command 1","command 2"
        </div><br/>

        <div className = "syntax-title">
        (Declaration Syntax)
        </div>
        <div className = "syntax">
        :cmd:cmdName
        </div>

      </div><br/><br/>



      <div>
        <div className = "command-title">
        Variables
        </div>
        <div className = "description">
        Variables can store either boolean values, integer values, string values, or command output. Variable names are optional.
        </div><p></p>
        <div className = "syntax-title">
        (Named Assignment Syntax)
        </div>
        <div className = "syntax">
          :var:myInt:5 
        </div>
        <div className = "syntax">
          :var:myBool:true 
        </div><div className = "syntax">
          :var:myString:"my string"
        </div><br/>

        <div className = "syntax-title">
        (Nameless Assignment Syntax)
        </div>
        <div className = "syntax">
        :var:5
        </div>
        <div className = "syntax">
        :var:true
        </div><div className = "syntax">
        :var:"my string"
        </div><br/>

        <div className = "syntax-title">
        (Named Output Assignment Syntax)
        </div>
        <div className = "syntax">
        :var:output: os command
        </div><br/>

        <div className = "syntax-title">
        (Nameless Output Assignment Syntax)
        </div>
        <div className = "syntax">
        :var: os command
        </div><br/>

        <div className = "syntax-title">
        (Insertion Syntax)
        </div>
        <div className = "syntax">
        :var:varName
        </div>
      </div><br/><br/>







      <div>
        <div className = "command-title">
        Arrays
        </div>
        <div className = "description">
          Arrays can store either boolean values, integer values, string values, or command output. Array names are optional.
        </div><p></p>
        <div className = "syntax-title">
        (Named Assignment Syntax)
        </div>
        <div className = "syntax">
          :array:myInt:5 
        </div>
        <div className = "syntax">
          :array:myBool:true 
        </div><div className = "syntax">
          :array:myString:"my string"
        </div><br/>

        <div className = "syntax-title">
        (Nameless Assignment Syntax)
        </div>
        <div className = "syntax">
        :array:5
        </div>
        <div className = "syntax">
        :array:true
        </div><div className = "syntax">
        :array:"my string"
        </div><br/>

        <div className = "syntax-title">
        (Named Output Assignment Syntax)
        </div>
        <div className = "syntax">
        :array:output: os command
        </div><br/>

        <div className = "syntax-title">
        (Nameless Output Assignment Syntax)
        </div>
        <div className = "syntax">
        :array: os command
        </div><br/>

        <div className = "syntax-title">
        (Looped Insertion Syntax)
        </div>
        <div className = "syntax">
        :array:arrayName
        </div><br/>

        <div className = "syntax-title">
        (Indexed Insertion Syntax)
        </div>
        <div className = "syntax">
        :array:arrayName[index]
        </div>
      </div><br/><br/>



<div>
  <div className = "command-title">
  Notes
  </div>
  <div className = "description">
    Notes store both the input and output of operating system commands. Note names are optional.
  </div><p></p>
  <div className = "syntax-title">
  (Named Output Assignment Syntax)
  </div>
  <div className = "syntax">
  :note:noteName: os command
  </div><br/>

  <div className = "syntax-title">
  (Nameless Output Assignment Syntax)
  </div>
  <div className = "syntax">
  :note: os command
  </div><br/>

  <div className = "syntax-title">
  (Insertion Syntax)
  </div>
  <div className = "syntax">
  :note:noteName
  </div>
</div><br/><br/>



<div>
  <div className = "command-title">
  Auto
  </div>
  <div className = "description">
  The auto command specifies wether or not notes of operating system commands and their output should be automatically saved.
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
  :auto:note:(on/off)
  </div>
</div><br/><br/>



<div>
  <div className = "command-title">
  Clip
  </div>
  <div className = "description">
  The clip command stores output of operating system commands to the os clipboard.
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
    :clip os command 
  </div>
</div><br/><br/>




<div>
  <div className = "command-title">
  Clones
  </div>
  <div className = "description">
    Clone count is optional. If clone count is omitted, one clone will be created. If clone count is omitted while used with looped arrays, clone creation count will be the same as array length. (Ex:  :spawn ping :array:myWebsites) If array “myWebsites” stored 3 ip addressees, 3 clones would be created, each storing a parameter based on an incremented array value (ping websiteone.com, ping websitetwo.com, ping websitethree.com).
  </div><p></p>
  <div className = "syntax-title">
  (Quantified Declaration Syntax)
  </div>
  <div className = "syntax">
   :clone:count optional os command
  </div><br/>

  <div className = "syntax-title">
  (Single Clone Declaration Syntax)
  </div>
  <div className = "syntax">
   :clone os command
  </div>
</div><br/><br/>


<div>
  <div className = "command-title">
  Spawns
  </div>
  <div className = "description">
  Spawn count is optional, if omitted one spawn will be created. If spawn count is omitted while used with looped arrays, spawn creation count will be the same as array length.
  </div><p></p>
  <div className = "syntax-title">
  (Quantified Declaration Syntax)
  </div>
  <div className = "syntax">
   :spawn:count optional os command
  </div><br/>

  <div className = "syntax-title">
  (Single Clone Declaration Syntax)
  </div>
  <div className = "syntax">
   :spawn os command
  </div>
</div><br/><br/>


<div>
  <div className = "command-title">
  Tab
  </div>
  <div className = "description">
  Opens a new shell tab.
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
    :tab
  </div>
</div><br/><br/>


<div>
  <div className = "command-title">
  Repeaters
  </div>
  <div className = "description">
  Repeaters store a command and a repeat count. Repeater names are optional, if omitted, a default name will be used based on the environment’s prefix. Repeaters do not require a colon at the end of statement. A repeater without a specified os command represents saved repeater execution.
  </div><p></p>
  <div className = "syntax-title">
  (Named Quantified Assignment Syntax)
  </div>
  <div className = "syntax">
  :repeat:name:number os command
  </div><br/>

  <div className = "syntax-title">
  (Nameless Quantified Assignment Syntax)
  </div>
  <div className = "syntax">
  :repeat:number os command
  </div><br/>

  <div className = "syntax-title">
  (Named Infinite Assignment Syntax)
  </div>
  <div className = "syntax">
  :repeat:name os command
  </div><br/>

  <div className = "syntax-title">
  (Nameless Infinite Assignment Syntax)
  </div>
  <div className = "syntax">
  :repeat os command
  </div><br/>

  <div className = "syntax-title">
  (Insertion Syntax)
  </div>
  <div className = "syntax">
  :repeat:repeaterName
  </div>
</div><br/><br/>


<div>
  <div className = "command-title">
  Back
  </div>
  <div className = "description">
  The back command changes the background color of shell.
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
  :back:"(color, rgb, or hexadecimal)"
  </div>
</div><br/><br/>


<div>
  <div className = "command-title">
  Color
  </div>
  <div className = "description">
  The color command changes the color of shell text.
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
  :color:"(color, rgb, or hexadecimal)"
  </div>
</div><br/><br/>

<div>
  <div className = "command-title">
  Lock
  </div>
  <div className = "description">
  The lock command hides DevOpsShell application until a key is pressed.
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
  :lock
  </div>
</div><br/><br/>


<div>
  <div className = "command-title">
  Invisible
  </div>
  <div className = "description">
  The invisible command hides shell data while continuing functionality.
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
  :invisible
  </div>
</div><br/><br/>

<div>
  <div className = "command-title">
  Visible
  </div>
  <div className = "description">
  The visible command shows shell data (usually used after the invisible command).
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
  :visible
  </div>
</div><br/><br/>

<div>
  <div className = "command-title">
  Show
  </div>
  <div className = "description">
  The show command displays the properties of saved data.
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
  :show :dataType:dataName
  </div>
</div><br/><br/>

<div>
  <div className = "command-title">
  Del
  </div>
  <div className = "description">
  The del command deletes saved data. To delete multiple objects at a time, include data separated by a space (:del :var:x :var:y :var:z).
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
  :del :dataType:dataName 
  </div>
</div><br/><br/>

<div>
  <div className = "command-title">
  Exit
  </div>
  <div className = "description">
  The exit command is used to close a spawn or clone window. The exit command only works inside of clone and spawn windows.
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
  :exit
  </div>
</div><br/><br/>

<div>
  <div className = "command-title">
  Quit
  </div>
  <div className = "description">
  The quit command is used to quit DevOpsShell application. The quit command only works inside of main window.
  </div><p></p>
  <div className = "syntax-title">
    (Syntax)
  </div>
  <div className = "syntax">
  :quit
  </div>
</div><br/><br/>

    </div>



    <footer className="footer">
  
  <div className="footer-bottom">
      <div className="container">
          <div className="row">
              <div className="col-xl-6 col-lg-6">
                  <div className="copiright">
                      <p>
                          DevOpsShell MATRIONIX Corp.<br/>
                          Copyright © 2021.All Rights Reserved By Matrionix Corp.<br/>
                          DevOpsShell Trademark Matrionix Corp. Patent Pending
                      </p>
                  </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                  <div className="social">
                      <a href="#!"><i className="icofont-facebook"></i></a>
                      <a href="#!"><i className="icofont-twitter"></i></a>
                      <a href="#!"><i className="icofont-pinterest"></i></a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</footer>



    </>
  );
}

export default Docs;
