import React from 'react';
import { observer } from "mobx-react";
import DialogComponent from "../Dialog";
// import mainLogo from "../assets/img/DevOpsShell_logo.jpg";
import mainLogo from "../assets/img/DevOpsShell_logo.png";
import smallLogo from "../assets/img/DevOpsShell_dock.png";
import downloadMacIcon from "../assets/img/mac-os-logo.svg";
//import laptopScreenShot from "../assets/img/screenshot_laptop.png";
import laptopScreenShot from "../assets/img/laptop_img.png";
import downloadWindowsIcon from "../assets/img/windows-logo.svg"
import bannerImg from "../assets/img/banner-img.png";
import macVersionLogo from "../assets/img/mac-version.svg";
import windowsVersionLogo from "../assets/img/windows-version.svg";
import downArrow from "../assets/img/download-arrow.svg";
import cloneIcon from "../assets/img/clone_icon.png";
import spawnIcon from "../assets/img/spawn_icon.png";
import arrayIcon from "../assets/img/array_icon.png"
import noteIcon from "../assets/img/note_icon.png"
import cliIcon from "../assets/img/cli_image.png";
import freeImg from "../assets/img/img0.png";
import standImg from "../assets/img/img-2.png";
import entImg from "../assets/img/img3.png";


function Home({store}) {

      const detectDownload = () => {
        if(window.navigator.appVersion.indexOf("Mac")>-1){
          macDownload();
        }else if(window.navigator.appVersion.indexOf("Win")>-1){
          windowsDownload();
        }else{
          macDownload();
        }
      }

      const macDownload = () => {
        window.location.href = "https://devopsshell.com/api/getLatestMacVersion";
      }

      const windowsDownload = () => {
        window.location.href = "https://devopsshell.com/api/getLatestWindowsVersion";
      }

      const standardClick = () => {
        window.localStorage.licenseType = "Standard";
        store.showDialog();
      }
    
      const enterpriseClick = () => {
        window.localStorage.licenseType = "Enterprise";
        store.showDialog();
      }

      return (
        <React.Fragment>
         {/* <div className="preloader">
          <div className="loader"></div> 
        </div>  */}
        <header className="header">
          <div className="header-top">
              <div className="container">
                  <div className="row">
                      <div className="col-xl-4 col-lg-5">
                          <div className="support">
                              <ul>
                                  {/* <li>
                                      <div className="lang-change-button">
                                          <select>
                                              <option>English</option>
                                              <option>Bengali</option>
                                              <option>Hindi</option>
                                          </select>
                                          <div className="icon">
                                              <i className="icofont-world"></i>
                                          </div>
                                      </div>
                                  </li> */}
                                  <li><span><i className="icofont-email"></i></span><a href = "mailto:support@devopsshell.com" style = {{fontSize:"14px",color:"#fff"}}>Support@DevOpsShell.com</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        <nav className="header-bottom mt-lg-5 mt-0 fixed-header">
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-xl-3 col-lg-3 d-xl-none d-lg-none d-block">
                        <div className="mobile-style">
                            <div className="row">
                                <div className="col-6">
                                    <div className="mobile-logo">
                                        <a href="index.html"><img src={smallLogo} alt=""/></a>
                                    </div>
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                    <div className="menu-button">
                                        <button className="responsive-menu-button">
                                            <i className="icofont-navigation-menu"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 d-xl-block d-lg-block d-none">

                        <div className="mobile-logo">
                            <img src={mainLogo} alt="" className="web-logo"/>
                        </div>

                    </div>
                    <div className="col-xl-9 col-lg-9" style = {{paddingRight: "10px"}}>
                        <div className="main-menu">
                            <ul>
                                <li><a href="/docs">View Docs</a></li>
                                <li><a href="/privacy">Privacy Policy</a></li>
                                {/* <li><a href="#pricing">Get A License</a>
                                </li> */}
                                <li><div id = "headerDownloadButton" onClick = {detectDownload}><span style = {{paddingRight: "10px"}}>&darr;</span><span>Download</span></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header> 








    {/* <img src = {laptopScreenShot} style = {{width: "500px", height: "300px", top: "203px", left: "800px", position: "absolute"}}/> */}



    <div className="banner">
        <div className="container">
            <div className="row">
                <div className="col-xl-7 col-lg-8">
                    <div className="banner-content">
                        <h3>DevOpsShell</h3>
                        <h2>Take your command line</h2>
                        <h2>to the next level</h2>       
                {/* <h7>  */}
                <p>Today's operating system shells have not changed for over 30 years! Try something new and upgrade the capabilities of your OS shell for free with DevOpsShell.</p>
                {/* </h7> */}
                <h3 style = {{textTransform:"none",color:"#000"}}>Download now for free!</h3>

                        <div className = "download-button" style = {{marginLeft: "2.5px",marginRight: "2.5px"}} onClick = {macDownload}>Download for Mac<img src = {downloadMacIcon} style = {{width: "30px",height:"30px",marginTop:"-5px",marginLeft:"5px"}}/></div>
                        <div className = "download-button" style = {{marginLeft: "2.5px",marginRight: "2.5px"}} onClick = {windowsDownload}>Download for Windows<img src = {downloadWindowsIcon} style = {{width: "30px",height:"30px",marginTop:"-5px",marginLeft:"5px"}}/></div>
                    </div>
                </div>
                <img src = {laptopScreenShot} 
                id = "laptop_img"
                style = {{
                    width: "600px", height: "400px", top: "70px", right: "63px", position: "absolute"
                    }}/>
            </div>
        </div>
    </div>














    <div className="container">
            <div className="row mt-3">
                <div className="col-lg-6 col-md-6 col-sm-12 text-lg-left text-md-left text-center mb-1">
                    <img className="version-size" src={macVersionLogo} alt=""/>
                    <img className="version-size" src={windowsVersionLogo} alt="" style = {{paddingTop: "8px",marginLeft:"20px"}}/>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-lg-right text-md-right text-center mb-1">
                    <img className="logo-size" src={mainLogo} alt=""/>
                </div>
            </div>
        </div>

        <div className="counter">
            <div className="scroll-button">
                <a href="#choosing-reason"><img src={downArrow} alt=""/></a>
            </div>
        </div>

        <div id="choosing-reason" className="choosing-reason">
        <section style={{padding:"5px"}}>

<div className="owl-carousel cards" data-autoplay="true" data-loop="true" data-nav="false" data-dots="false"
    data-items="4" data-items-laptop="4" data-items-tab="2" data-items-mobile="2" data-items-mobile-sm="1">

    <div className="item mt-3 my-3">
        <div className="iq-blog-box bg-white" style={{borderRadius:"14px",height:"450px"}}>
            <div className="iq-blog-image clearfix">
                <img src={cloneIcon} style = {{width:"80px",height:"80px"}} className="img-fluid center-block px-2  pt-4 card-img" alt="devopsshell clone"/>
                <div className="iq-blog-detail p-2">
                    <div className="blog-title">
                        <a href="#!">
                          <h5 className="mb-1">:clone</h5>
                        </a>
                    </div>
                    <p className="mb-0  " id="just1"  >:clone command launches multiple shells which accept the commands to execute from the parent shell, allowing a user to execute a task accross multiple shells simultaneously. 
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div className="item mt-3 my-3">
        <div className="iq-blog-box bg-white" style={{borderRadius:"14px",height:"450px"}}>
            <div className="iq-blog-image clearfix">
                <img src={spawnIcon} style = {{width:"80px",height:"80px"}} className="img-fluid center-block px-2  pt-4 card-img" alt="devopsshell spawn"/>
                <div className="iq-blog-detail p-2">
                    <div className="blog-title">
                        <a href="#!">
                            <h5 className="mb-1">:spawn</h5>
                        </a>
                    </div>

                    <p className="mb-0  " id="just1" >:spawn command launches a new shell with the same functionality as the parent shell. This is used to run multiple instances of DevOpsShell without occupying the parent shell.</p>
                </div>
            </div>
        </div>
    </div>
    <div className="item mt-3 my-3">
        <div className="iq-blog-box bg-white" style={{borderRadius:"14px",height:"450px"}}>
            <div className="iq-blog-image clearfix">
                <img src={arrayIcon} style = {{width:"80px",height:"80px"}} className="img-fluid center-block px-2  pt-4 card-img" alt="devopsshell array"/>
                <div className="iq-blog-detail p-2">
                    <div className="blog-title">
                        <a href="#!">
                            <h5 className="mb-1">:array</h5>
                        </a>
                    </div>

                    <p className="mb-0  " id="just1">:array command is used to create an array of items which can be used to launch any OS command on multiple items in sequential order in the shell or spawned dynamically to multiple shells.</p>
                </div>
            </div>
        </div>
    </div>
    <div className="item mt-3 my-3">
        <div className="iq-blog-box bg-white" style={{borderRadius:"14px",height:"450px"}}>
            <div className="iq-blog-image clearfix">
                <img src={noteIcon} style = {{width:"80px",height:"80px"}} className="img-fluid center-block px-2  pt-4 card-img"   alt="devopsshell note"/>
                <div className="iq-blog-detail p-2">
                    <div className="blog-title">
                        <a href="#!">
                            <h5 className="mb-1">:note</h5>
                        </a>
                    </div>
                    <p className="mb-1  " id="just1">:note command is used to store the command entered and the ouput of the same command to a note. 
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div className="item mt-3 my-3">
        <div className="iq-blog-box bg-white" style={{borderRadius:"14px",height:"450px"}}>
            <div className="iq-blog-image clearfix">
                <img src={cliIcon} style = {{width:"80px",height:"80px"}} className="img-fluid center-block px-2  pt-4 card-img" alt="devopsshell in most cli's"/>
                <div className="iq-blog-detail p-2">
                    <div className="blog-title">
                        <a href="#!">
                            <h5 className="mb-1">Works in most CLIs</h5>
                        </a>
                    </div>
                    <p className="mb-0  " id="just1"  >Use DevOpsShell commands and features within most CLIs.
                        Example: In a MySQL or MongoDB shell, use the DevOpsShell features such as sending the database query results to a DevOpsShell internal note system or saving find commands to reusable variables.
                    </p>
                </div>
            </div>
        </div>
    </div>                
</div>
</section>
        </div>























    <div className="about">
      <div className="container">
          <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-5 col-md-10 d-xl-flex d-lg-flex d-block align-items-center">
                  <div className="about-content">
                      <div className="section-title-area">
                          <h5>What is</h5>
                          <h2>DevOpsShell</h2>
                      </div>
                      {/* <p>DevOpsShell is a stand alone application when used instead of a standard shell adds many advance features which expand the capabilities of operating systems.<br/>DevOpsShell runs within it's own ecosystem allowing a user to use any operating system command or application in the command prompt along side DevOpsShell commands and features, Expanding the abilities of the command shell.<br/>
                      <br/>All of DevOpsShell's features also function within 3rd party application CLI's.
                      </p> */}
                      <p>
                        DevOpsShell is a stand alone application that when used in place of a standard shell, expands the capabilities of operating systems. DevOpsShell runs within its own ecosystem, allowing a user to use any OS command or application (mongo, sql, ssh, etc) while continuing to use DevOpsShell's commands, extending the abilities of the command shell tremendously.
                        <br/><br/>All of DevOpsShell's features function within 3rd party applications and CLIs.
                      </p>
                  </div>
              </div>

              <div className="col-xl-7 col-lg-7">
              <div className="part-img">
                <img src={bannerImg} alt="" style = {{width:"100%"}}/>                     
              </div>
              </div>
          </div>
      </div>
  </div>







  <div className="call-to-action-area">
        <div className="container">
            <div className="row justify-content-xl-between justify-content-lg-center justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10" style = {{display:"flex",alignItems:"center"}}>
                    <div className="call-to-action-content wow fadeInLeft" style = {{marginTop: "-20px"}}>
                        <h3>Introduction</h3>
                        <p className="text-white">In this video, a brief explenation of DevOpsShell and how it works is demonstrated. This video covers some of DevOpsShell's simple however, very powerful tools!</p>
                    </div>
 
                </div>
                <div className="col-xl-5 col-lg-0">
                    <div className="part-img">
                        <iframe width="450px" height="450px" src="https://www.youtube.com/embed/gUAW2nIRogI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                       
                    </div> 
                </div>
            </div>
        </div>
    </div>








  <div className="call-to-action-area">
        <div className="container">
            <div className="row justify-content-xl-between justify-content-lg-center justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10" style = {{display:"flex",alignItems:"center"}}>
                    <div className="call-to-action-content wow fadeInLeft" style = {{marginTop: "-20px"}}>
                        <h3>CLI Insertion</h3>
                        <p className="text-white">Within DevOpsShell you can launch an application CLI such as MongoDB, MySQL, or SSH and use DevOpsShell's features right within the CLI. DevOpsShell's commands even work in a remote host with features like note capturing or launching spawns with arrays, all within the product's cli!</p>
                    </div>
 
                </div>
                <div className="col-xl-5 col-lg-0">
                    <div className="part-img">
                        <iframe width="450px" height="450px" src="https://www.youtube.com/embed/a_uvm9jlp24" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                       
                    </div> 
                </div>
            </div>
        </div>
    </div>











  <div className="call-to-action-area">
        <div className="container">
            <div className="row justify-content-xl-between justify-content-lg-center justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10" style = {{display:"flex",alignItems:"center"}}>
                    <div className="call-to-action-content wow fadeInLeft" style = {{marginTop: "40px"}}>
                        <h3> &#62; :clone</h3>
                        <p className="text-white"> Example syntax:<br/> :clone:3  <br/>command will launch 3 shells which accept the commands to execute from the parent shell, allowing a user to execute a task accross multiple shells simultaneously.</p>
                    </div>
 
                </div>
                <div className="col-xl-5 col-lg-0">
                    <div className="part-img" style = {{marginTop: "20px"}}>
                        <iframe width="450px" height="450px" src="https://www.youtube.com/embed/cx5UtZFvAe0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                       
                    </div>
                </div>
            </div>
        </div>
    </div> 


    

    <div className="call-to-action-area">
        <div className="container">
            <div className="row justify-content-xl-between justify-content-lg-center justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10" style = {{display:"flex",alignItems:"center"}}>
                    <div className="call-to-action-content wow fadeInLeft" style = {{marginTop: "-120px"}}>
                        <h3>&#62; :spawn</h3>
                        <p className="text-white"> Example syntax:<br/> :spawn:3  <br/>launches 3 shells with the same functionality as the parent shell. This is used to run multiple instances of DevOpsShell that run independently. </p>
                    </div>
 
                </div>
                <div className="col-xl-5 col-lg-0">
                    <div className="part-img">
                        <iframe width="450px" height="450px" src="https://www.youtube.com/embed/qEo5jAGWv88" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                       
                    </div>
                </div>
            </div>
        </div>
    </div> 






    {/* <div className="call-to-action-area">
        <div className="container">
            <div className="row justify-content-xl-between justify-content-lg-center justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10" style = {{display:"flex",alignItems:"center"}}>
                    <div className="call-to-action-content wow fadeInLeft" style = {{marginTop: "-130px"}}>
                        <h3>&#62; :note:myDir dir</h3>
                        <p className="text-white">In a dos environment will run the command "dir" and store the output to a DevOpsShell note called 'myDir' for future reference. </p>
                    </div>
 
                </div>
                <div className="col-xl-5 col-lg-0">
                    <div className="part-img">
                        <iframe width="500px" height="450px" src="https://www.youtube.com/embed/1h98lDwDi54" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                       
                    </div>
                </div>
            </div>
        </div>
    </div> */}











    <div className="call-to-action-area">
        <div className="container">
            <div className="row justify-content-xl-between justify-content-lg-center justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10" style = {{display:"flex",alignItems:"center"}}>
                    <div className="call-to-action-content wow fadeInLeft" style = {{marginTop: "-90px"}}>
                        <h3>&#62; :array</h3>
                        <p className="text-white">
                          Create an array called myWebsites storing a list of websites, then launch the os curl command on the array<br/>
                          &#62; :array:myWebsites:"FaxZoom.com"<br/>
                          &#62; :array:myWebsites:"TrigCalc.com"<br/>
                          &#62; :array:myWebsites:"DevOpsShell.com"<br/>
                          &#62; curl :array:myWebsites<br/><br/> 
                          Same as above, while dynamically launching a spawn for each item in the array list, to execute in its own independent shell:<br/>    
                          &#62; :spawn curl  :array:myWebsites
                        </p>
                    </div>
 
                </div>
                <div className="col-xl-5 col-lg-0">
                    <div className="part-img">
                        <iframe width="450px" height="450px" src="https://www.youtube.com/embed/AWEOFYjDZ40" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                       
                    </div>
                </div>
            </div>
        </div>
    </div>










        <div className="pricing-content">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-8">
                    <div className="section-title-area">
                        <h5>Advantages of using</h5>
                        <h2>DevOpsShell</h2> 
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="single-service">
                        <div className="part-text">
                            <h3>Clones</h3>
                            <p>The DevOpsShell Clones are a very powerful feature for Network Engineers, Database Administrators, etc...
                                Clones will allow the user to use a parent window to enter commands and have the commands execute in multiple shells simultaneously. This feature is great for working on multiple remote hosts or databases at the same time without having to repeat the same task in each machine!</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="single-service">
                        <div className="part-text">
                            <h3>Spawns</h3>
                            <p>Spawns are deployed to work independent of the parent. Spawns have all the functionality of the parent without tying down the parent shell.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="single-service">
                        <div className="part-text">
                            <h3>Repeaters</h3>
                            <p>Repeaters are used to launch a command and assign the number of times to repeat the command. Repeaters can launch with an infinite value as well. When used with spawns or arrays, repeaters are extremely flexible.</p>
                        </div> 
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="single-service">
                        <div className="part-text">
                            <h3>Arrays</h3>
                            <p>DevOpsShell has a built in array system which allows commands to execute based on the number of items in the array. This function removes the need to retype repetitive tasks.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="single-service">
                        <div className="part-text"> 
                            <h3>Variables</h3>
                            <p>Variables are used to store commands or values. This allows the user to save commands that are often repeated or access variables that are often needed.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="single-service">
                        <div className="part-text">
                            <h3>Notes</h3>
                            <p>Notes allow for the storage of commands and the executed output to be saved to a DevOpsShell internal note. This feature can be used to store output of database requests, remote session output, etc... </p>
                            <a href="#">.</a>
                        </div>
                    </div>
                </div>


                <div className="col-xl-4 col-lg-4 col-md-6" style = {{marginTop: "20px"}}>
                    <div className="single-service">
                        <div className="part-text">
                            <img src={smallLogo} style = {{width:"50px",height:"50px",display:"inline"}} alt="devopsshell in most cli's"/>
                            <h3 style = {{display:"inline",marginLeft:"10px"}}>CLI Insertion</h3>
                            <p style = {{width: "700px"}}>
                                All of DevOpsShell's features also function within 3rd party applications/CLIs.<br/>
                                Example: from DevOpsShell a user can launch a CLI such as MySQL, MongoDB, or SSH to a remote host and use DevOpsShell features within the 3rd party CLI!
                                <br/>- A user can query a database and send the output of the query to a note using the :note command.
                                <br/>- Repeat a task such as a database status using the :repeat command.
                                <br/>- Monitor a network device by using the :spawn :repeat, to spawn a child window and start an infinite repeater within the command line!
                                <br/><br/>Above are just a few examples of the features available in DevOpsShell.
                            </p>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>

    {/* <div className="service" id = "pricing">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-8">
                    <div className="section-title-area">
                        <h5>DevOpsShell</h5>
                        <h2>Download for free</h2>
                        <p>Or purchase a License.</p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="single-service">
                        <img src={freeImg} alt="" width="200" height="150"/> 
                        <br/>
                        <a href = "#!" style = {{fontSize: "20px",color: "#558af2",marginTop:"5px",marginBottom:"5px",display:"block",textDecoration:"none",paddingTop:"20px",paddingBottom:"10px"}}>FREE</a>
                        <div className="part-text">
                            <h3>Download</h3>                    
                            <ul className = "license-list" style = {{listStyle:"none",padding:0,textAlign:"center"}}>
                                <li>Notes: 30</li>
                                <li>Variables: 10 </li>
                                <li>Arrays: 5</li>
                                <li>Repeaters: 2</li>
                                <li>Environments: 2</li>
                                <li>Spawns: 2</li>
                                <li>Clones: 2</li>
                                <li>History: 10</li>
                                 <li>&nbsp; </li>                               
                            </ul>
                            <a href="#!" onClick = {detectDownload}>Download Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="single-service">
                        <img src={standImg} alt="" width="200" height="150"/> 
                        <br/>
                        <a href = "#!" style = {{fontSize: "20px",color: "#558af2",marginTop:"5px",marginBottom:"5px",display:"block",textDecoration:"none"}}>USD $59<br/>(One Time Purchase)</a>
                        <div className="part-text">
                            <h3>Standard Version</h3>
                            <ul className = "license-list" style = {{listStyle:"none",padding:0,textAlign:"center"}}>
                                <li>Notes: 300</li>
                                <li>Variables: 300 </li>
                                <li>Arrays: 200</li>
                                <li>Repeaters: 100</li>
                                <li>Environments: 10</li>
                                <li>Spawns: 100</li>
                                <li>Clones: 100</li>
                                <li>History: 300</li>
                                <li> &nbsp;</li>                                
                            </ul>
                            <a href="#!" onClick={standardClick}>Purchase License</a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="single-service"> 
                        <img src={entImg} alt="" width="200" height="150"/> 
                        <br/>
                        <a href = "#!" style = {{fontSize: "20px",color: "#558af2",marginTop:"5px",marginBottom:"5px",display:"block",textDecoration:"none"}}>USD $159<br/>(One Time Purchase)</a>
                        <div className="part-text">
                            <h3>Enterprise Version</h3>
                            <ul className = "license-list" style = {{listStyle:"none",padding:0,textAlign:"center"}}>
                                <li>Notes: Unlimited</li>
                                <li>Variables: Unlimited </li>
                                <li>Arrays: Unlimited</li>
                                <li>Repeaters: Unlimited</li>
                                <li>Environments: Unlimited</li>
                                <li>Spawns: Unlimited</li>
                                <li>Clones: Unlimited</li>
                                <li>History: Unlimited</li>
                                <li>(Based on memory)</li>
                            </ul>
                            <a href="#!" onClick={enterpriseClick}>Purchase License</a>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div> */}
        <footer className="footer">
  
        <div className="footer-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="copiright">
                            <p>
                                DevOpsShell MATRIONIX Corp.<br/>
                                Copyright © 2023.All Rights Reserved By Matrionix Corp.<br/>
                                DevOpsShell Trademark Matrionix Corp. Patent Pending
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="social">
                            <a href="#!"><i className="icofont-facebook"></i></a>
                            <a href="#!"><i className="icofont-twitter"></i></a>
                            <a href="#!"><i className="icofont-pinterest"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>


    {
      store.dialogIsVisible === true
      ?
      <DialogComponent store = {store}/>
      :
      ""
    }

    </React.Fragment>
      );
}

export default observer(Home);
