
import mainLogo from "../assets/img/DevOpsShell_logo.png";
import smallLogo from "../assets/img/DevOpsShell_dock.png";

function Privacy() {
  const detectDownload = () => {
    if(window.navigator.appVersion.indexOf("Mac")>-1){
      macDownload();
    }else if(window.navigator.appVersion.indexOf("Win")>-1){
      windowsDownload();
    }else{
      macDownload();
    }
  }

  const macDownload = () => {
    window.location.href = "https://devopsshell.com/api/getLatestMacVersion";
  }

  const windowsDownload = () => {
    window.location.href = "https://devopsshell.com/api/getLatestWindowsVersion";
  }

  return (



<>



<header className="header">
          <div className="header-top">
              <div className="container">
                  <div className="row">
                      <div className="col-xl-4 col-lg-5">
                          <div className="support">
                              <ul>
                                  <li><span><i className="icofont-email"></i></span><a href = "mailto:support@devopsshell.com" style = {{fontSize:"14px",color:"#fff"}}>Support@DevOpsShell.com</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        <nav className="header-bottom mt-lg-5 mt-0 fixed-header">
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-xl-3 col-lg-3 d-xl-none d-lg-none d-block">
                        <div className="mobile-style">
                            <div className="row">
                                <div className="col-6">
                                    <div className="mobile-logo">
                                        <a href="index.html"><img src={smallLogo} alt=""/></a>
                                    </div>
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                    <div className="menu-button">
                                        <button className="responsive-menu-button">
                                            <i className="icofont-navigation-menu"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 d-xl-block d-lg-block d-none">

                        <div className="mobile-logo">
                          <a href = "/"><img src={mainLogo} alt="" className="web-logo"/></a>
                        </div>

                    </div>
                    <div className="col-xl-9 col-lg-9" style = {{paddingRight: "10px"}}>
                        <div className="main-menu">
                            <ul>
                                <li><a href="/docs">View Docs</a></li>
                                <li><a href="https://devopsshell.com/privacy">Privacy Policy</a>
                                </li>
                                <li><div id = "headerDownloadButton" onClick = {detectDownload}><span style = {{paddingRight: "10px"}}>&darr;</span><span>Download</span></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header> 















    <span id = "backHome"><a href = "/">Back Home</a></span>
  <div id = "privacy-policy-container">
  
    <h2>
      Privacy Policy
    </h2>
    <p>
      At DevOpsShell, we understand the importance of protecting our users' privacy. This privacy policy governs the collection, use, and storage of personal information by our desktop application.
    </p>
    <h3>
      Data Collection:
    </h3>
    <ul>
      <li>
        DevOpsShell does not collect any personal information from users.
      </li>
      <li>
        DevOpsShell does not store any data on our servers or share data with any third parties.
      </li>      
    </ul>
    <h3>
      Data Security:
    </h3>
    <ul>
      <li>
      DevOpsShell does not store any personal information, so there is no need for data security measures.
      </li>
    </ul>
    <h3>
      Data Retention:
    </h3>
    <ul>
      <li>
      As DevOpsShell does not collect or store any personal information, there is no need for data retention policies.
      </li>
    </ul>
    <h3>
      Changes to this Privacy Policy:
    </h3>
    <ul>
      <li>We reserve the right to make changes to this privacy policy at any time.</li>
      <li>Any changes will be posted on our website and will become effective immediately.</li>
    </ul>
    <h3>
      Contact Us:
    </h3>
    <ul>
      <li>
        If you have any questions or concerns about this privacy policy, please contact us at support@devopsshell.com.
      </li>
    </ul>
    <p>
      This privacy policy is effective as of 01/30/2023.
    </p>
  </div>

  <footer className="footer">
    <div className="footer-bottom">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="copiright">
                        <p>
                            DevOpsShell MATRIONIX Corp.<br/>
                            Copyright © 2021.All Rights Reserved By Matrionix Corp.<br/>
                            DevOpsShell Trademark Matrionix Corp. Patent Pending
                        </p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="social">
                        <a href="#!"><i className="icofont-facebook"></i></a>
                        <a href="#!"><i className="icofont-twitter"></i></a>
                        <a href="#!"><i className="icofont-pinterest"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </footer>



    </>
  );
}

export default Privacy;
