import { types } from "mobx-state-tree";

const Store = types.model("Store", {
    dialogIsVisible: false,
    dialogView: ""
}).actions(self=>({
    showDialog(){
        self.dialogIsVisible = true;
    },
    hideDialog(){
        self.dialogIsVisible = false;
    },
    setDialogView(view){
        self.dialogView = view;
    }
}));

export default Store;